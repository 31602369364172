<script lang="ts" setup>
export interface MenuItem {
  label: string
  icon: string
  action: () => void
  isHidden?: boolean
  isLoading?: boolean
  color?: 'text' | 'primary' | 'success' | 'warning' | 'danger'
}

defineProps<{
  items: MenuItem[]
  dense?: boolean
}>()

const onClick = (event: MouseEvent, item: MenuItem) => {
  if ('isLoading' in item) {
    // We want to prevent the menu from closing if an action can cause a loading state
    event.stopPropagation()
  }
  item.action()
}
</script>

<template>
  <v-menu v-if="!items.every(item => item.isHidden)" offset-y bottom right content-class="skr-context-menu">
    <template #activator="{ on }">
      <v-btn data-cy="context_menu_button" class="activator" text icon v-on="on">
        <v-icon color="text"> $vuetify.icons.more_vert </v-icon>
      </v-btn>
    </template>
    <v-list class="pa-1" :dense="dense">
      <template v-for="item in items">
        <v-list-item
          v-if="!item.isHidden"
          :key="item.label"
          :class="['menu-item', `menu-item--${item.color ?? 'primary'}`]"
          :disabled="item.isLoading"
          @click="onClick($event, item)"
        >
          <v-list-item-action class="mr-4">
            <v-progress-circular v-if="item.isLoading" size="24" indeterminate />
            <v-icon v-else>{{ `$vuetify.icons.${item.icon}` }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.label }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<style lang="sass">
.skr-context-menu
  $colorMap: ('text': $c-text, 'primary': $c-primary, 'success': $c-success, 'warning': $c-warning, 'danger': $c-danger)
  .menu-item
    border-radius: $bw
    cursor: pointer
    font-weight: bold
    color: $c-text
    @each $color, $value in $colorMap
      &--#{$color}
        &:hover
          color: $value !important
          background-color: rgba($value, 0.12) !important
          *
            color: $value !important
</style>
